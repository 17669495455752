<section id="aboutus" class="about">
    <div class="container-fluid">
      <div class="row">

        <div class="col-lg-5 align-items-stretch video-box" style='background-image: url("assets/images/abou-bg.jpg");'>
        </div>

        <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

          <div class="content">
            <h3>Bespoke Designing Services to  <strong>Cater Your Needs</strong></h3>
            <p>
              We process your architectural layout and design thoughts into photo-realistic designs so that you can visualise your final interior or exterior before construction. Sit back, relax and let our 3D artist take care of your next dream project.
            </p>
            <p class="font-italic">
              We listen and work together with our clients to produce eye catching and amazing visuals. 3D World Studio are proud for the services we provide to make our client happy.
            </p>
            <ul>
              <li><i class="fas fa-check-double"></i>High quality 3D rendering services for interior and exterior designing.</li>
              <li><i class="fas fa-check-double"></i>Our in-house design team uses latest 3D visualisation software to produce interactive and photorealistic designs. </li>
              <li><i class="fas fa-check-double"></i> As one of the best 3D visual designing company, we make sure to work closely with our clients during the whole project to achieve desired results and feel of a space.</li>
            </ul>
            <p>
              Our team of professional designers interpret your visions and ideas into innovative and trendy designs at an affordable price. Weather you like contemporary, modern or both, we can design every corner of your house with best space planning and comfort. Our 3D designs will give you an idea about how you are dream project will look in future, after it is constructed successfully. 
            </p>
          </div>

        </div>
      </div>
    </div>
</section>