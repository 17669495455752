<section id="contactus" class="contact">
    <div class="container">

      <div class="section-title">
        <h2><span>Contact</span> Us</h2>
        <p>Get in touch with us now. Fill out the form and one of our expert will get back to you soon. </p>
      </div>
    </div>

    <div class="map">
      <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3556.1915876379767!2d75.73151941540478!3d26.960831564506524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db2df05ceccdd%3A0xe7eb04e4462edc00!2sMotherland%20Public%20Senior%20Secondary%20School!5e0!3m2!1sen!2sin!4v1592824246171!5m2!1sen!2sin" frameborder="0" allowfullscreen></iframe>

    </div>

    <div class="container mt-5">

      <div class="info-wrap">
        <div class="row">
          <div class="col-lg-3 col-md-6 info">
            <i class="fas fa-map-marker-alt"></i>
            <h4>Location:</h4>
            <p>Niwaru Road Jhotwara Jaipur (Rajasthan)</p>
          </div>

          <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
            <i class="far fa-clock"></i>
            <h4>Open Hours:</h4>
            <p>Monday-Saturday:<br>09:30 AM - 08:00 PM</p>
          </div>

          <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
            <i class="fas fa-envelope"></i>
            <h4>Email:</h4>
            <p>mkk0143@gmail.com<br>jyotidesigner01@gmail.com</p>
          </div>

          <div class="col-lg-3 col-md-6 info mt-4 mt-lg-0">
            <i class="fas fa-phone-alt"></i>
            <h4>Call:</h4>
            <p>+91 9667506739<br>+91 7976821029</p>
          </div>
        </div>
      </div>

      <form action="http://localhost/contact.php" method="post" role="form" class="php-email-form">
        <div class="form-row">
          <div class="col-md-6 form-group">
            <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
            <div class="validate"></div>
          </div>
          <div class="col-md-6 form-group">
            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
            <div class="validate"></div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" name="telephone" id="telephone" placeholder="telephone Number" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
          <div class="validate"></div>
        </div>
        <div class="form-group">
          <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
          <div class="validate"></div>
        </div>
        <div class="mb-3">
          <div class="loading">Loading</div>
          <div class="error-message"></div>
          <div class="sent-message">Your message has been sent. Thank you!</div>
        </div>
        <div class="text-center"><button type="button" class="btnsend_scc" (click) = "onClick($event)">Send Message</button></div>
      </form>

      <!-- The Modal -->
  <div class="modal" id="" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <!-- <div class="modal-header">
          <h4 class="modal-title">User Details:</h4>
          <button type="button" class="close" data-dismiss="modal" (click) = "hide()">&times;</button>
        </div> -->
        
        <!-- Modal body -->
        <div class="modal-body">
          Thanks for joining us, we will contact you soon.
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click) = "hide()">Close</button>
        </div>
        
      </div>
    </div>

    </div>
    </div>
  </section>