<section id="testimonialss" class="testimonials">
    <div class="container">

      <div class="owl-carousel testimonials-carousel">

        <div class="testimonial-item">            
          <h3>SMD Architect</h3>
          <h4>Ceo &amp; Founder</h4>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <p>
            <i class="fas fa-quote-left"></i>
            Great service provided by “3dworldstudio”. They have been proven their work, more importantly their quality of work is good. 
            <i class="fas fa-quote-right"></i>
          </p>
        </div>

        <div class="testimonial-item">            
          <h3>Studio9</h3>
          <h4>Ceo</h4>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <p>
            <i class="fas fa-quote-left"></i>
            They have excellent staff and really hard workers. I am extremely happy with their services.
            <i class="fas fa-quote-right"></i>
          </p>
        </div>

        <div class="testimonial-item">            
          <h3>Interior way</h3>
          <h4>Business Expert</h4>
          <div class="stars">
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
            <i class="fas fa-star"></i>
          </div>
          <p>
            <i class="fas fa-quote-left"></i>
            They listen to our needs, requirements and provide services on timely and appropriate responses every time.	
            <i class="fas fa-quote-right"></i>
          </p>
        </div>

      </div>

    </div>
  </section>