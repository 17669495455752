<section id="gallery" class="gallery">
    <div class="container-fluid">

      <div class="section-title">
        <h2>Some photos from <span>Completed Projects</span></h2>
        <p>Check out the quality of service that we offer and revamp your next dream project into reality with us</p>
      </div>

      <div class="row no-gutters">

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery01.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery01.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery02.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery02.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery03.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery03.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery04.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery04.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery05.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery05.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery06.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery06.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery07.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery07.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>

        <div class="col-lg-3 col-md-4">
          <div class="gallery-item">
            <a href="assets/images/gallery/gallery08.jpg" class="venobox" data-gall="gallery-item">
              <img src="assets/images/gallery/gallery08.jpg" alt="" class="img-fluid">
            </a>
          </div>
        </div>


      </div>

    </div>
  </section>