<section id="eventss" class="events">
    <div class="container">

        <div class="section-title">
            <h2>Our <span>Special</span> Projects</h2>
        </div>

        <div class="owl-carousel events-carousel">

            <div class="row event-item">
            <div class="col-lg-6">
                <img src="assets/images/special_project01.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content">
                <h3>Smart Residences</h3>
                <p class="font-italic">
                Presently looking at the small space, we design smart residences where all the living facilities are available.
                </p>
                <p>
                    We also keep in mind the architectural art while designing the residence. We provide all the facilities in the residence such as luxury rooms,  Proper Hall Space Great Kitchen Designing, Modern Living, and the entire space of car parking. Also, we remove complete space for the need for things like garden and swimming pool and design it all of our designed smart residences. All these facilities are available in design
                </p>
            </div>
            </div>

            <div class="row event-item">
            <div class="col-lg-6">
                <img src="assets/images/special_project02.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content">
                <h3>Master Bedroom</h3>
                <p class="font-italic">
                 All items in the master bedroom we designed are designed to take a Proper Space.
                </p>
                <ul>
                <li><i class="far fa-check-circle"></i> We designed Bed Ideas for Master Bedroom</li>
                <li><i class="far fa-check-circle"></i> Place Lighting Adjustment for Master Bedroom</li>
                <li><i class="far fa-check-circle"></i> All Ideas are Unique Design For Bedroom</li>
                </ul>
                <p>
                    A brief definition of a master bedroom is given from our side that all items adorn the bedroom and put on the perfect spot. All items compliment each other and are a story based
                </p>
            </div>
            </div>

            <div class="row event-item">
            <div class="col-lg-6">
                <img src="assets/images/special_project03.jpg" alt="" class="img-fluid">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 content">
                <h3>Digital Garden</h3>
                <p class="font-italic">
                A digital marriage garden, seeing that people are vying to get married quickly.
                </p>
                <ul>
                <li><i class="far fa-check-circle"></i> In Digital Garden, we digitize and prove the Wedding couple stage of the garden..</li>
                <li><i class="far fa-check-circle"></i> Luxury sofa and hall to accommodate the guest seating the best of the space.</li>
                <li><i class="far fa-check-circle"></i> We provide entry gate and complete coverage of the wedding garden with unique lighting and designing.</li>
                </ul>
                <p>
                  We have good knowledge of designing digital gardens and we have been working on this for quite some time.
                </p>
            </div>
            </div>

        </div>

    </div>
  </section>