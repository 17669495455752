<footer id="footer">
    <div class="container">
      <h1 class="text-light"><a href="index.html"><span><img src="assets/images/logo.png" alt="LOGO"/></span></a></h1>
      <p>Visit our office and we would love to discuss your project idea over a coffee. </p>
      <div class="copyright">
        &copy; Copyright <strong><span>3dworldstudio.com</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
       Click here for create Website <a href="https://www.linkedin.com/in/jeet-k-9b419b186/" target="_blank">Jeet kumar</a>
      </div>
    </div>
  </footer>