<section id="services" class="chefs">
    <div class="container">

      <div class="section-title">
        <h2>Our <span>Services</span></h2>
        <p>Our professional services cover everything from plan rendering, architectural and graphic design, to 3D interiors and exteriors designing.</p>
      </div>

      <div class="row">

        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category01.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>3D Exterior Visualization</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category02.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>3D Interior Visualization</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category03.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>3D Plan Rendering</h4>
            </div>
          </div>
        </div>


        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category04.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Interior Planning & Construction</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category05.gif" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>Walkthrough Animation</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="member">
            <div class="pic"><img src="assets/images/category06.jpg" class="img-fluid" alt=""></div>
            <div class="member-info">
              <h4>3D Product Rendering</h4>
            </div>
          </div>
        </div>

      </div>

    </div>
</section>