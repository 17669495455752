<section id="team" class="specials">
    <div class="container">

      <div class="section-title">
        <h2>Check our <span>Experts</span></h2>
        <p>Have a look at our team of skilful and qualified designers that offerbest in-class services as per your requirement. </p>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <ul class="nav nav-tabs flex-column">
            <li class="nav-item">
              <a class="nav-link active show" data-toggle="tab" href="#tab-1">Manish Kumar(CEO)</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-2">Yogendra </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#tab-3">Kashish</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-9 mt-4 mt-lg-0">
          <div class="tab-content">
            <div class="tab-pane active show" id="tab-1">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Interior Master</h3>
                  <p>Manish has reliably led the interior department of 3d world studio form for over 5 years and is one of the longest-standing members of our team. Manish has mentored numerous young architects and interior designers with his refined approach to design. His knowledge of the industry, attention to detail, and having a demeanor that exudes professionalism keeps clients happy and coming back for more attention.
                    I set up a 3D world studio in 2015, upon my return from working in Dehli,  India. 
                     It was a Jaipur city to work in interior architecture. Initially,
                    During my time in the 3D world studio, I also worked for other companies, who interior designed luxury hotels and high-end office spaces throughout  East India and Australia.
                    </p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/images/testimonials-1.jpg" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-2">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Civil Engineer</h3>
                  <p>Yogendra is a Civil Engineer & 3D Visualization Artist with 3+ years of experience. the past 5 years Yogendra has worked with several Indian Architectural firms creating a state of the Art 3D Visuals and Animations. He is constantly experimenting and continuously developing new and better ideas and he gives different ways to help the Clients understand their Architecture better and easily.  he also Contributing to art design concepts in relation to Interior, models, Landscaping, and enhancing the "look and feel" of the walkthrough. he understands very well how to handle client queries and as well as their problems.</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/images/testimonials-2.jpg" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="tab-pane" id="tab-3">
              <div class="row">
                <div class="col-lg-8 details order-2 order-lg-1">
                  <h3>Architect</h3>
                  <p>Kashish has 3+ years experience in the Architect field, he has made a lot of Buildings, office, and residence map, its drawn line is so perfect that the client can get complete Happily, it understands the client's requirements very easily complete project within deadline He manages to serve the results according to client expectations</p>
                </div>
                <div class="col-lg-4 text-center order-1 order-lg-2">
                  <img src="assets/images/testimonials-3.jpg" alt="" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>