    <!-- ======= Top Bar ======= -->
    <section id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
        <div class="container text-right">
          <i class="fas fa-phone-alt"></i> +91 9667506739
          <i class="far fa-clock"></i> Mon-Sat: 09:30 AM - 08:30 PM
        </div>
      </section>
  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center">

      <div class="logo mr-auto">
        <h1 class="text-light"><a href="index.html"><span><img src="assets/images/logo.png" alt="LOGO"/></span></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <nav id="nav-menu-container">
        <div class="coll align-self-center">
           <div id="main-menu-toggle"><img src="assets/images/menu.svg" alt="icon"></div>
           <div class="black-layer"></div>
           <div class="hede_nav main-menu-bx">
              <div id="close-main-menu"><img src="assets/images/close.svg" alt="icon"></div>
      <!--  <nav class="nav-menu d-none d-lg-block"> -->
         <ul>
           <li class="active"><a href="index.html">Home</a></li>
           <li><a href="#aboutus">About</a></li>
           <li><a href="#services">Services</a></li>
           <li><a href="#team">Team</a></li>
           <li><a href="#gallery">Gallery</a></li>
           <li><a href="#contactus">Contact</a></li>
         </ul>
       <!-- </nav> --><!-- .nav-menu -->

     </div>
 </div>
</nav>

    </div>
  </header><!-- End Header -->
 <!-- ======= Hero Section ======= -->
 <section id="hero">
    <div class="hero-container">
          <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

            <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

            <div class="carousel-inner" role="listbox">
              <!-- Slide 1 -->
                  <div class="carousel-item active" style="background: url(assets/images/slide/slide-1.jpg);">
                    <div class="carousel-container">
                      <div class="carousel-content">
                        <h2 class="animated fadeInDown"><span>3D World Studio </span>- Welcome</h2>
                        <p class="animated fadeInUp">3D Rendering Services are important when you are thinking to renovate an old building or planning to construct a new building from an old site. We offer range of services that can help you to PLAN and EXECUTE your idea. </p>
                      </div>
                    </div>
                  </div>

                  <!-- Slide 2 -->
                 <div class="carousel-item" style="background: url(assets/images/slide/slide-2.jpg);">
                        <div class="carousel-container">
                          <div class="carousel-content">
                            <h2 class="animated fadeInDown">3D Designs</h2>
                            <p class="animated fadeInUp">Realistic architectural designs that will improve the presence of your brand in Real Estate Marketing.We combine 3D rendering services with 3D architectural visualisation process to bring your design concept to life. Our eye catchy 3D visualisation services will make your property more attractive for your clients.</p>
                          </div>
                        </div>
                 </div>

                  <!-- Slide 3 -->
                <div class="carousel-item" style="background: url(assets/images/slide/slide-3.jpg);">
                        <div class="carousel-background"><!-- <img src="assets/img/slide/slide-3.jpg" alt=""></div> -->
                            <div class="carousel-container">
                              <div class="carousel-content">
                                <h2 class="animated fadeInDown">Architectural Rendering</h2>
                                <p class="animated fadeInUp">3D World Studio has a team of expert designers that can transformclient's imagination into architectural animation, rendering, photomontages, that gives realistic feel of the building. Our proven track record and long list of satisfied clients speaks about the quality we provide.</p>
                              </div>
                            </div>
                      </div>
                   </div>
                <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
                  <i class="carousel-control-prev-icon fas fa-angle-left" aria-hidden="true"></i>   
                </a>
                <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
                   <i class="carousel-control-prev-icon fas fa-angle-right" aria-hidden="true"></i>   
                </a>
              </div>
        </div>
    </div>
  </section><!-- End Hero -->