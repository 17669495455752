import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { WhyChooseComponent } from './why-choose/why-choose.component';
import { ServicesComponent } from './services/services.component';
import { ExpertsComponent } from './experts/experts.component';
// import { EspecialProjectsComponent } from './especial-projects/especial-projects.component';
import { SpecialProjectsComponent } from './special-projects/special-projects.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavbarComponent,
    AboutComponent,
    WhyChooseComponent,
    ServicesComponent,
    ExpertsComponent,
    // EspecialProjectsComponent,
    SpecialProjectsComponent,
    GalleryComponent,
    ClientsComponent,
    ContactUsComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
